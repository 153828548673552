@tailwind base;
@tailwind components;
@tailwind utilities;

/* ecolabs website colors
 dark grey: 727376 
 */

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
  font-size: 1.1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: white;
  border-radius: 0.5rem;
  background-color: #727376;
}

button:hover {
  background-color: #65bc50;
}

input {
  border: 1px solid #d1d5db;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border-radius: 0.35rem;
  font-size: 1.1rem;
  color: #374151;
}

input[type="checkbox"] {
  width: 1.3rem;
  height: 1.3rem;
  accent-color: #65bc50; /* #65bc50 */
  cursor: pointer;
  border: 2px solid #ccc;
  border-radius: 4px;
}

input[type="radio"] {
  transform: scale(1.7);
}

input:active,
input:focus,
textarea:focus,
textarea:active {
  border: 2px solid #65bc50; /* #65BC50 | 006ba1 */
  outline: none;
}

/* select {
} */

select:focus {
  border: 2px solid #65bc50; /* #65BC50 | 006ba1 */
  outline: none;
}

label {
  font-size: 1.2rem;
  padding-bottom: 0.1rem;
  color: #374151;
}

option {
  font-size: 1.1rem;
}

textarea {
  border: 1px solid #d1d5db;
  border-radius: 0.35rem;
  padding-bottom: 0.5rem;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
  padding-top: 0.5rem;
  font-size: 1.1rem;
}
