.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*  */

.parent {
  padding-left: 0rem;
  padding-right: 0rem;
}

.parent .nav {
  /* max-width: 500px;  */
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.parent .nav button {
  width: 49%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.user_form {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
}

.user_form input {
  display: inline-block;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.user_form button {
  display: inline-block;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

/* admin */

.admin {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
}

.admin .number_learners {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.admin .number_learners .number {
  font-weight: bold;
}

.admin .learners_container .learners {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  background-color: lightgray;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem;
}

.admin .learners_container .learners .learner_info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  background-color: lightgray;
}

.admin .learners_container .learners .date {
  color: blue;
}

.divform {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
